import { httpApi } from "@/services/httpApi";
import { buildQueryParams } from "@/lib/queryHelper";
import constants from "@/constants";

class ApiMealTemplates {
  async get(options) {
    let baseUrl = `beetle/v1/meal-templates`;
    const queryParams = buildQueryParams(options);
    return await httpApi.get(`${baseUrl}?${queryParams.toString()}`);
  }
  async show(id, options) {
    let baseUrl = "beetle/v1/meal-templates/" + id;
    const queryParams = buildQueryParams(options);
    let responseType =
      options && options.action === "download" ? "blob" : "json";
    return await httpApi.get(`${baseUrl}?${queryParams.toString()}`, {
      responseType: responseType,
    });
  }
  async create(data) {
    return await httpApi.post("beetle/v1/meal-templates", data);
  }
  async update(id, data) {
    return await httpApi.post("beetle/v1/meal-templates/" + id, data);
  }
  async changeUser(id, data) {
    return await httpApi.post(
      `beetle/v1/meal-templates/${id}/change-user`,
      data
    );
  }
  async copyWeekDay(id, data) {
    return await httpApi.post(
      `beetle/v1/meal-templates/${id}/copy-week-day`,
      data
    );
  }
  async resetWeek(templateId, data) {
    return await httpApi.post(
      `/beetle/v1/meal-templates/${templateId}/reset-week`,
      data
    );
  }
  async resetWeekDay(templateId, data) {
    return await httpApi.post(
      `/beetle/v1/meal-templates/${templateId}/reset-day`,
      data
    );
  }
  async delete() {}
  getDaysPerWeekOptions(type) {
    let options = [7];
    if (type === constants.mealTemplate.type.AUTOMATIC) {
      options = [5, 6, 7];
    }
    let result = [];
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: options[i],
      });
    }
    return result;
  }
  getWeeksOptions(type) {
    let options = [4];
    if (type === constants.mealTemplate.type.AUTOMATIC) {
      options = [4, 8, 12];
    }
    let result = [];
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: options[i],
      });
    }
    return result;
  }
  async getMealTemplateTypeList() {
    return await httpApi.get("beetle/v1/misc/lists/meal-template-type");
  }
  async getMealTemplateStatusList() {
    return await httpApi.get("beetle/v1/misc/lists/meal-template-status");
  }
  async upsertMealTemplateDays(id, data) {
    return await httpApi.post(
      "beetle/v1/meal-templates/" + id + "/meal-template-days",
      data
    );
  }
  searchOptions(searchValue, searchFields, searchFilter) {
    const options = {
      draw: 5,
      columns: [
        {
          data: "id",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
        {
          data: "title",
          name: "",
          searchable: true,
          orderable: true,
          search: {
            value: "",
            regex: false,
          },
        },
      ],
      order: [
        {
          column: 0,
          dir: "asc",
        },
      ],
      start: 0,
      length: 100,
      search: {
        value: searchValue || "",
        regex: false,
      },
    };
    if (Array.isArray(searchFields) && searchFields.length) {
      options.columns = options.columns.map((column) => {
        if (!searchFields.includes(column.data) && column.data) {
          return { ...column, searchable: false };
        }
        return column;
      });
    }
    if (searchFilter) {
      options.filter = searchFilter;
    }
    return options;
  }
  async replaceVariation(data) {
    return await httpApi.post(
      "beetle/v1/meal-templates/replace-variation",
      data
    );
  }
  async getReplaceableMealTemplates() {
    return await httpApi.get(
      "beetle/v1/meal-templates/replaceable-meal-templates"
    );
  }
}

export default new ApiMealTemplates();
