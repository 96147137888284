import { httpApi } from "@/services/httpApi";

class ApiMiscList {
  async bagDeliveryStatus() {
    return await httpApi.get("beetle/v1/misc/lists/bag-delivery-status");
  }
  async bagMovementStatus() {
    return await httpApi.get("beetle/v1/misc/lists/bag-movement-status");
  }
  async invoiceStatus() {
    return await httpApi.get("beetle/v1/misc/lists/invoice-status");
  }
  async orderStatus() {
    return await httpApi.get("beetle/v1/misc/lists/order-status");
  }
  getCustomMealFoodTypes() {
    let options = ["protein", "veg", "carb", "sauce"];
    let result = [];
    for (let i in options) {
      result.push({
        value: options[i],
        label: options[i],
        id: i,
      });
    }
    return result;
  }
  async nutritionQueueStatus() {
    return await httpApi.get("beetle/v1/misc/lists/nutrition-queue-status");
  }
  async activityLevel() {
    return await httpApi.get("beetle/v1/misc/lists/activity-level");
  }
  async goal() {
    return await httpApi.get("beetle/v1/misc/lists/goal");
  }
  async gender() {
    return await httpApi.get("beetle/v1/misc/lists/gender");
  }
  async validDaysPerWeek() {
    return await httpApi.get("beetle/v1/misc/lists/valid-days-per-week");
  }
  async validNumberOfWeeks() {
    return await httpApi.get("beetle/v1/misc/lists/valid-number-of-weeks");
  }
  async manualPaymentMethods() {
    return await httpApi.get("beetle/v1/misc/lists/manual-payment-methods");
  }
  async breakfastDislike() {
    return await httpApi.get("beetle/v1/misc/lists/breakfast-dislike");
  }
  async nextAvailableStartDate() {
    return await httpApi.get(
      "beetle/v1/misc/lists/get-next-available-start-date"
    );
  }
  async cutOff(date) {
    return await httpApi.get(`beetle/v1/misc/lists/get-cutoff?date=${date}`);
  }
  async refundPaymentSources() {
    return await httpApi.get("beetle/v1/misc/lists/refund-payment-sources");
  }
  async refundTypes() {
    return await httpApi.get("beetle/v1/misc/lists/refund-types");
  }
  banks() {
    let options = [
      "Emirates NBD",
      "HSBC",
      "ADCB",
      "FAB",
      "CITIBANK",
      "ADIB (ABU DHABI ISLAMIC BANK)",
      "MASHREQ",
      "DIB (DUBAI ISLAMIC BANK)",
      "EMIRATES ISLAMIC BANK",
      "NATIONAL BANK OF RAS AL-KHAIMAH",
      "RAK BANK",
      "NBF (NATIONAL BANK OF FUJAIRAH)",
      "STANDARD CHARTERED BANK",
      "AL HILAL BANK",
      "OTHER BANK",
      "COMMERCIAL BANK OF DUBAI",
      "Al maryah community bank",
    ];
    let result = [];
    for (let i in options) {
      result.push({
        id: options[i],
        name: options[i],
      });
    }
    return result;
  }
  async refundStatuses() {
    return await httpApi.get("beetle/v1/misc/lists/refund-statuses");
  }
  getOffDaysChoices() {
    let options = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    let result = [];
    for (let i = 0; i < options.length; i++) {
      let value = i + 1;
      if (i === 6) {
        value = 0;
      }
      result.push({
        value: value,
        label: options[i],
        id: value,
      });
    }
    return result;
  }
}

export default new ApiMiscList();
