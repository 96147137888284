import { httpApi } from "@/services/httpApi";

class apiClientOrder {
  async amendOrder(clientId, orderId, data) {
    const url = `beetle/v1/clients/${clientId}/orders/${orderId}/amend-order`;
    return await httpApi.post(url, data);
  }
  async show({ clientId, orderId, quickView }) {
    const url = `beetle/v1/clients/${clientId}/orders/${orderId}${
      quickView ? "/show-day-pack" : ""
    }`;
    return await httpApi.get(url);
  }
  async allergensDislikes(clientId, orderId) {
    return await httpApi.get(
      `beetle/v1/clients/${clientId}/orders/${orderId}/allergen-dislike-order`
    );
  }
  async downloadPdf(clientId, orderId) {
    return await httpApi.get(
      `beetle/v1/clients/${clientId}/orders/${orderId}/meal-pdf`,
      { responseType: "blob" }
    );
  }
  async getDayPackMeals({ clientId, orderId, dayPackId }) {
    const url = `beetle/v1/clients/${clientId}/orders/${orderId}/show-day-pack/${dayPackId}/show-meals`;
    return await httpApi.get(url);
  }
  async getDayPackAllergensDislikes({ clientId, orderId, dayPackId }) {
    const url = `beetle/v1/clients/${clientId}/orders/${orderId}/show-day-pack/${dayPackId}/allergen-dislike`;
    return await httpApi.get(url);
  }
}

export default new apiClientOrder();
